import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const NasmPage = () => {
  return (
    <Layout pageTitle="NASM">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/Waste-free-ontario.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>NASM Planning</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row>
            <Col>
              <StaticImage 
                src="../../../media/land_application_approvals.jpg"
                alt="form, approvals, farm data, and regulations" 
              />
            </Col>
            <Col>
              <p>
                When using biosolids, you must adhere to government rules and regulations that are constantly evolving. We make it our business to keep on top of the latest requirements and will help you with everything from non agricultural source material (NASM) approvals to nutrient management plans, and nutrient management strategies.
              </p>
              <p>
                We’ll help you plan for the nutrients your crops need, reduce nutrient inputs, and provide soil sampling to determine nutrient requirements. At the end of it all, you’ll have a plan that projects your farm’s nutrient use for up to 5 years.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row>
            <Col>
              <p>
                Farming today is more technologically and scientifically advanced than ever before. Wessuc continues to be a leader in their field by not only adapting to advanced methods but introducing them as well. In addition to providing our clients with GPS records of spreading in their fields, we enhance and contribute to their nutrient management (Ag and Non-Ag sources) and their soil sampling records.
              </p>
              <p>
                Our farm data management tools include NMAN data input, updating, and setup. We also assist the tracking of field inputs, outputs, and nutrient needs which leads to improved timing of application. Call us today to learn more about how we can help your operation.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../../media/land_application_farm_data_management.jpg"
                alt="a man surveys a field, planting pink flags" 
              />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default NasmPage